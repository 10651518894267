
import { Vue, Component } from 'vue-property-decorator';
import IconMessage from '@/shared/resources/components/IconMessage.vue';
import { redirect } from '@/shared/lib/support/router/RouterUtils';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';

@Component({
  components: {
    IconMessage,
  },
})
export default class CartOrderMBankPaynowStatusView extends Vue {
  /**
   * Lifecycle hooks
   */
  private async created() {
    const paymentId: string = this.$route.query.paymentId as string;
    const paymentStatus: string = this.$route.query.paymentStatus as string;

    // get online from api
    await OnlineOrderCoreModel
      .api()
      .request({
        method: 'get',
        url: '/online-orders/by-payment-extra/' + paymentId,
      });

    // get from store
    const order: OnlineOrderCoreModel | null = await OnlineOrderCoreModel
      .query()
      .where('paymentExtra', paymentId)
      .first();

    if (order && paymentStatus === 'CONFIRMED') {
      return redirect({ name: 'cart.paynow.success' });
    }

    redirect({ name: 'cart.paynow.error' });
  }

  /**
   * Render
   */
  private render() {
    //
  }
}
